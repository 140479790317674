@import url(../css/ress.css);
@import url(../css/font.css);
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700|Ropa+Sans");
/*-----------------commmon----------------------*/
body {
  font-family: 'Ropa Sans', 'Noto Sans JP', sans-serif;
  line-height: 1.3; }
  body::before, body::after {
    content: '';
    display: block;
    height: 0;
    width: 0; }
  body::before {
    margin-bottom: calc(-0.3em + 0px); }
  body::after {
    margin-top: calc(-0.3em + 0px); }

.c-box {
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 0 0; }

.c-ttl-style {
  display: flex;
  align-items: center;
  text-align: center; }
  .c-ttl-style .c-ttl-style-main {
    font-weight: 700;
    font-size: 2.8rem; }
  .c-ttl-style .c-ttl-style-sub {
    display: block;
    font-size: 0.9rem;
    font-weight: 400; }

.c-ttl-style:before,
.c-ttl-style:after {
  border-top: 1px solid;
  content: "";
  flex-grow: 1; }

.c-ttl-style:before {
  margin-right: 2rem; }

.c-ttl-style:after {
  margin-left: 2rem; }

/*-----------------each section----------------------*/
.main__img {
  display: block;
  width: 100%; }

.problem {
  background: #606163;
  color: #fff; }
  .problem .c-box .c-list {
    padding: 50px 0 80px; }
    .problem .c-box .c-list-item {
      display: grid;
      grid-template-columns: 80px 500px 1fr;
      padding: 0 0 50px;
      grid-gap: 20px 0; }
      .problem .c-box .c-list-item-num {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        border: 4px solid #fff;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8rem;
        font-weight: 400;
        text-align: center; }
        .problem .c-box .c-list-item-num span {
          font-size: 3rem;
          padding: 2px 0 0 0;
          font-weight: 700;
          line-height: 0.8; }
          .problem .c-box .c-list-item-num span::before, .problem .c-box .c-list-item-num span::after {
            content: '';
            display: block;
            height: 0;
            width: 0; }
          .problem .c-box .c-list-item-num span::before {
            margin-bottom: calc(-0.05em + 0px); }
          .problem .c-box .c-list-item-num span::after {
            margin-top: calc(-0.05em + 0px); }
      .problem .c-box .c-list-item-ttl {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 10px 0 0 50px;
        letter-spacing: 0.05rem; }
        .problem .c-box .c-list-item-ttl .txt-state-color {
          color: #feb20a; }
        .problem .c-box .c-list-item-ttl .txt-state-paragraph {
          display: block; }
      .problem .c-box .c-list-item-des {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        padding: 0 0 0 50px; }
      .problem .c-box .c-list-item-txt {
        color: #ccc;
        font-size: 0.95rem;
        letter-spacing: 0.07rem;
        line-height: 1.35rem; }
      .problem .c-box .c-list-item-illust {
        width: 80%;
        padding: 20px 0 0; }
        .problem .c-box .c-list-item-illust img {
          display: block;
          width: 100%; }
      .problem .c-box .c-list-item .illust-multi {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 5px 15px; }
        .problem .c-box .c-list-item .illust-multi .illust1 {
          grid-row: 1 / 2;
          grid-column: 1 / 2; }
        .problem .c-box .c-list-item .illust-multi .illust2 {
          grid-row: 2 / 3;
          grid-column: 1 / 2; }
        .problem .c-box .c-list-item .illust-multi .illust3 {
          grid-row: 1 / 3;
          grid-column: 2 / 3; }
      .problem .c-box .c-list-item-img {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
        margin: 0 0 0 25px;
        width: 140px;
        align-self: flex-end; }
        .problem .c-box .c-list-item-img img {
          display: block;
          width: 100%; }

.solution {
  background: #efefef; }
  .solution .c-list {
    padding: 50px 0 80px; }
    .solution .c-list-item {
      display: grid;
      grid-template-columns: 80px 500px 1fr;
      padding: 0 0 50px;
      grid-gap: 20px 0; }
      .solution .c-list-item-num {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        border: 4px solid #000;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8rem;
        font-weight: 400;
        text-align: center; }
        .solution .c-list-item-num span {
          font-size: 3rem;
          padding: 2px 0 0 0;
          font-weight: 700;
          line-height: 0.8; }
          .solution .c-list-item-num span::before, .solution .c-list-item-num span::after {
            content: '';
            display: block;
            height: 0;
            width: 0; }
          .solution .c-list-item-num span::before {
            margin-bottom: calc(-0.05em + 0px); }
          .solution .c-list-item-num span::after {
            margin-top: calc(-0.05em + 0px); }
      .solution .c-list-item-ttl {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 10px 0 0 50px;
        letter-spacing: 0.05rem; }
        .solution .c-list-item-ttl .txt-state-color {
          color: #ea4e00; }
        .solution .c-list-item-ttl .txt-state-paragraph {
          display: block; }
      .solution .c-list-item-des {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        padding: 0 0 0 50px; }
      .solution .c-list-item-txt {
        color: #727171;
        font-size: 0.95rem;
        letter-spacing: 0.07rem;
        line-height: 1.35rem; }
      .solution .c-list-item-illust {
        width: 80%;
        padding: 20px 0 0; }
        .solution .c-list-item-illust img {
          display: block;
          width: 100%; }
      .solution .c-list-item .illust-multi {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 5px 15px; }
        .solution .c-list-item .illust-multi .illust1 {
          grid-row: 1 / 2;
          grid-column: 1 / 2; }
        .solution .c-list-item .illust-multi .illust2 {
          grid-row: 2 / 3;
          grid-column: 1 / 2; }
        .solution .c-list-item .illust-multi .illust3 {
          grid-row: 1 / 3;
          grid-column: 2 / 3; }
      .solution .c-list-item-img {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
        margin: 0 0 0 25px;
        width: 140px;
        align-self: flex-start; }
        .solution .c-list-item-img img {
          display: block;
          width: 100%; }
  .solution .c-vocab {
    width: 800px;
    margin: 80px auto 0;
    padding: 50px;
    background: #fff;
    display: flex;
    align-items: flex-start; }
    .solution .c-vocab .sp-ttl {
      display: none; }
    .solution .c-vocab-img {
      width: 400px;
      padding: 0 50px 0 0; }
      .solution .c-vocab-img img {
        display: block;
        width: 100%; }
    .solution .c-vocab-box {
      width: 400px; }
    .solution .c-vocab-sub {
      font-size: 1.2rem;
      font-weight: 700; }
      .solution .c-vocab-sub span {
        font-size: 1.7rem; }
    .solution .c-vocab-ttl {
      font-size: 3.4rem; }
    .solution .c-vocab-price {
      padding: 20px 0 0;
      font-size: 1.7rem; }
      .solution .c-vocab-price span {
        font-size: 2.4rem;
        letter-spacing: 0.01rem;
        padding: 0 0 0 5px; }
    .solution .c-vocab-note {
      color: #727171; }
      .solution .c-vocab-note li:before {
        content: "-";
        padding: 0 2px 0 0; }
      .solution .c-vocab-note li {
        font-size: 0.85rem;
        letter-spacing: 0.05rem;
        line-height: 1.4rem;
        font-family: 'Noto Sans JP'; }
    .solution .c-vocab-link {
      display: block;
      margin: 30px 0;
      padding: 14px 0;
      text-align: center;
      font-weight: 700;
      color: #fff;
      background: #ea4e00;
      border-radius: 10px;
      width: 300px;
      font-size: 1.1rem; }

.buy .c-vocab {
  width: 100%;
  margin: 0;
  padding: 80px 0 0;
  background: #fff;
  display: flex;
  align-items: flex-start; }
  .buy .c-vocab .sp-ttl {
    display: none; }
  .buy .c-vocab-img {
    width: 400px;
    padding: 0 50px 0 0; }
    .buy .c-vocab-img img {
      display: block;
      width: 100%; }
  .buy .c-vocab-box {
    width: 400px; }
  .buy .c-vocab-sub {
    font-size: 1.2rem;
    font-weight: 700; }
    .buy .c-vocab-sub span {
      font-size: 1.7rem; }
  .buy .c-vocab-ttl {
    font-size: 3.4rem; }
  .buy .c-vocab-price {
    padding: 20px 0 0;
    font-size: 1.7rem; }
    .buy .c-vocab-price span {
      font-size: 2.4rem;
      letter-spacing: 0.01rem;
      padding: 0 0 0 5px; }
  .buy .c-vocab-note {
    color: #727171; }
    .buy .c-vocab-note li:before {
      content: "-";
      padding: 0 2px 0 0; }
    .buy .c-vocab-note li {
      font-size: 0.85rem;
      letter-spacing: 0.05rem;
      line-height: 1.4rem;
      font-family: 'Noto Sans JP'; }
  .buy .c-vocab-link {
    display: block;
    margin: 30px 0;
    padding: 14px 0;
    text-align: center;
    font-weight: 700;
    color: #fff;
    background: #ea4e00;
    border-radius: 10px;
    width: 300px;
    font-size: 1.1rem; }

.buy__illust {
  width: 150px;
  margin-left: auto;
  padding: 0 20px 0 0; }
  .buy__illust img {
    display: block;
    width: 100%; }

.buy__distinction {
  display: flex;
  background: url("../img/bg_distinction.png") no-repeat bottom left 50px;
  background-size: 70px;
  background-color: #efefef;
  margin: 30px 0 80px;
  padding: 50px;
  flex-wrap: wrap; }

.buy__img {
  width: 50%;
  padding: 0 30px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative; }
  .buy__img img {
    display: block;
    width: 47.5%; }

.buy__img:after {
  content: "NEW";
  color: #fff;
  background: #ff0000;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  line-height: 70px;
  text-align: center;
  top: 160px;
  right: 15px; }

.buy__ttl {
  font-size: 1rem;
  width: 100%;
  letter-spacing: 0.03rem; }

.buy__list li:first-child {
  border-bottom: 1px solid #231815;
  padding: 0 0 30px;
  margin: 0 0 10px; }

.buy__name {
  font-size: 2rem;
  padding: 20px 0 0;
  line-height: 0.8; }
  .buy__name::before, .buy__name::after {
    content: '';
    display: block;
    height: 0;
    width: 0; }
  .buy__name::before {
    margin-bottom: calc(-0.05em + 0px); }
  .buy__name::after {
    margin-top: calc(-0.05em + 0px); }
  .buy__name span {
    color: #ff0000;
    border-bottom: 2px solid #ff0000;
    font-size: 1.5rem;
    margin: 0 0 0 5px; }

.buy__price {
  padding: 5px 0 0;
  color: rgba(0, 0, 0, 0.4); }
  .buy__price span {
    padding: 0 0 0 2px;
    font-size: 1.6rem; }

.buy__button {
  display: block;
  width: 280px;
  margin: 10px auto 0;
  padding: 15px;
  border-radius: 16px;
  text-align: center;
  color: #fff;
  background: #000656; }

/*-----------------media query----------------------*/
@media screen and (max-width: 799px) {
  .c-box {
    max-width: 450px; }
  .problem .c-box {
    padding: 50px 0 30px; }
    .problem .c-box .c-list {
      padding: 50px 0 20px; }
      .problem .c-box .c-list-item {
        grid-template-columns: 50px 1fr;
        padding: 0 0 45px;
        grid-gap: 0; }
        .problem .c-box .c-list-item-num {
          grid-column: 1 / 2;
          border: 3px solid #fff;
          width: 55px;
          height: 55px; }
          .problem .c-box .c-list-item-num span {
            font-size: 1.5rem; }
        .problem .c-box .c-list-item-ttl {
          padding: 0 0 0 20px;
          font-size: 1.25rem; }
          .problem .c-box .c-list-item-ttl .txt-state-paragraph {
            display: block; }
        .problem .c-box .c-list-item-txt {
          font-size: 0.85rem;
          padding: 20px 0 0; }
        .problem .c-box .c-list-item-des {
          grid-column: 1 / 3;
          padding: 0; }
        .problem .c-box .c-list-item-img {
          grid-row: 3 / 4;
          grid-column: 1 / 3;
          margin: 0 auto;
          width: 100px; }
  .solution .c-box {
    padding: 80px 0 0; }
  .solution .c-vocab {
    max-width: 520px;
    margin: 50px auto 0;
    padding: 30px; }
    .solution .c-vocab-img {
      width: 45%;
      padding: 0 20px 0 0; }
    .solution .c-vocab-box {
      width: 55%; }
    .solution .c-vocab-sub {
      font-size: 0.9rem; }
      .solution .c-vocab-sub span {
        font-size: 1.2rem; }
    .solution .c-vocab-ttl {
      font-size: 2.4rem; }
    .solution .c-vocab-link {
      width: 250px;
      margin: 15px auto 20px; }
    .solution .c-vocab-note li {
      font-size: 0.75rem;
      letter-spacing: 0.03rem;
      line-height: 1.2rem;
      padding: 0 0 8px; }
    .solution .c-vocab-note li:last-child {
      padding: 0; }
  .solution .c-list {
    padding: 0 0 80px; }
    .solution .c-list-item {
      grid-template-columns: 50px 1fr;
      padding: 0 0 50px;
      grid-gap: 0; }
      .solution .c-list-item-num {
        grid-column: 1 / 2;
        width: 55px;
        height: 55px; }
        .solution .c-list-item-num span {
          font-size: 1.5rem; }
      .solution .c-list-item-ttl {
        padding: 0 0 0 20px;
        font-size: 1.25rem; }
        .solution .c-list-item-ttl .txt-state-paragraph {
          display: block; }
      .solution .c-list-item-txt {
        font-size: 0.85rem;
        padding: 20px 0 0; }
      .solution .c-list-item-des {
        grid-column: 1 / 3;
        padding: 0; }
      .solution .c-list-item-img {
        display: none; }
      .solution .c-list-item-illust {
        margin: 0 auto;
        padding: 20px 0 0; }
    .solution .c-list-item:last-child {
      padding: 0; }
  .buy .c-box {
    padding: 50px 0 0; }
  .buy .c-vocab {
    max-width: 520px;
    margin: 0 auto;
    padding: 50px 0 0; }
    .buy .c-vocab-img {
      width: 45%;
      padding: 0 20px 0 0; }
    .buy .c-vocab-box {
      width: 55%; }
    .buy .c-vocab-sub {
      font-size: 0.8rem; }
      .buy .c-vocab-sub span {
        font-size: 1.2rem; }
    .buy .c-vocab-ttl {
      font-size: 2.4rem; }
    .buy .c-vocab-link {
      width: 250px;
      margin: 15px auto 20px; }
    .buy .c-vocab-note li {
      font-size: 0.75rem;
      letter-spacing: 0.03rem;
      line-height: 1.2rem;
      padding: 0 0 8px; }
    .buy .c-vocab-note li:last-child {
      padding: 0; }
  .buy__illust {
    display: none; }
  .buy__distinction {
    margin: 50px auto;
    padding: 30px 30px 150px; }
  .buy__img {
    width: 100%;
    padding: 0; }
  .buy__img:after {
    width: 50px;
    height: 50px;
    line-height: 50px;
    top: 85%;
    right: -3%; }
  .buy__link {
    margin: 0 auto; }
  .buy__ttl {
    padding: 30px 0 0; } }

@media screen and (max-width: 549px) {
  .problem {
    display: block; }
    .problem .c-ttl-style {
      display: none; }
    .problem .c-box {
      padding: 0 30px 30px; }
      .problem .c-box .c-list-item-ttl .txt-state-paragraph {
        display: inline; }
  .solution .c-box {
    padding: 50px 30px 0; }
  .solution .c-ttl-style {
    display: block;
    font-size: 1.2rem; }
    .solution .c-ttl-style .c-ttl-style-main {
      font-size: 1.5rem; }
    .solution .c-ttl-style .c-ttl-style-sub {
      display: inline;
      font-size: 1.5rem;
      font-weight: 700; }
  .solution .c-ttl-style:before, .solution .c-ttl-style:after {
    border-top: none;
    margin: 0; }
  .solution .c-vocab {
    width: 320px;
    flex-wrap: wrap;
    padding: 30px;
    margin: 30px auto 0; }
    .solution .c-vocab .sp-ttl {
      display: block; }
      .solution .c-vocab .sp-ttl-sub {
        font-size: 0.9rem;
        font-weight: 700; }
        .solution .c-vocab .sp-ttl-sub span {
          font-size: 1.15rem; }
      .solution .c-vocab .sp-ttl-main {
        font-size: 2.7rem; }
    .solution .c-vocab-img {
      width: 100%;
      padding: 0; }
    .solution .c-vocab-box {
      width: 100%; }
    .solution .c-vocab-sub, .solution .c-vocab-ttl {
      display: none; }
    .solution .c-vocab-link {
      width: 250px;
      margin: 15px auto 25px; }
    .solution .c-vocab-price {
      padding: 10px 0 0; }
    .solution .c-vocab-note li {
      font-size: 0.8rem;
      letter-spacing: 0.01rem;
      padding: 0 0 8px; }
  .solution .c-list {
    padding: 0 0 50px; }
    .solution .c-list-item {
      grid-template-columns: 50px 1fr;
      padding: 0 0 50px;
      grid-gap: 0; }
      .solution .c-list-item-num {
        grid-column: 1 / 2;
        width: 55px;
        height: 55px; }
        .solution .c-list-item-num span {
          font-size: 1.5rem; }
      .solution .c-list-item-ttl {
        padding: 0 0 0 20px;
        font-size: 1.25rem; }
        .solution .c-list-item-ttl .txt-state-paragraph {
          display: inline; }
      .solution .c-list-item-txt {
        font-size: 0.85rem;
        padding: 20px 0 0; }
      .solution .c-list-item-des {
        grid-column: 1 / 3;
        padding: 0; }
      .solution .c-list-item-img {
        display: none; }
      .solution .c-list-item-illust {
        margin: 0 auto;
        padding: 20px 0 0; }
  .buy .c-box {
    padding: 0; }
  .buy .c-ttl-style {
    display: none; }
  .buy .c-vocab {
    width: 320px;
    flex-wrap: wrap;
    padding: 30px;
    margin: 30px auto 0; }
    .buy .c-vocab .sp-ttl {
      display: block; }
      .buy .c-vocab .sp-ttl-sub {
        font-size: 0.9rem;
        font-weight: 700; }
        .buy .c-vocab .sp-ttl-sub span {
          font-size: 1.15rem; }
      .buy .c-vocab .sp-ttl-main {
        font-size: 2.7rem; }
    .buy .c-vocab-img {
      width: 100%;
      padding: 0; }
    .buy .c-vocab-box {
      width: 100%; }
    .buy .c-vocab-sub, .buy .c-vocab-ttl {
      display: none; }
    .buy .c-vocab-link {
      width: 250px;
      margin: 15px auto 25px; }
    .buy .c-vocab-price {
      padding: 10px 0 0; }
    .buy .c-vocab-note li {
      font-size: 0.8rem;
      letter-spacing: 0.01rem;
      padding: 0 0 8px; }
  .buy__illust {
    display: none; }
  .buy__distinction {
    margin: 0 auto;
    padding: 50px 30px 150px; }
  .buy__img {
    width: 100%;
    padding: 0; }
  .buy__img:after {
    width: 50px;
    height: 50px;
    line-height: 50px;
    top: 85%;
    right: -3%; }
  .buy__ttl {
    padding: 30px 0 0; } }

@media screen and (max-width: 320px) {
  .solution .c-ttl-style .c-ttl-style-main {
    font-size: 1.2rem; }
  .solution .c-ttl-style .c-ttl-style-sub {
    font-size: 1.2rem; } }
